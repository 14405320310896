@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.table-style::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-style {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: visible;
  overflow-y: auto;
}

.flexCol {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexWrap {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
